import React, { useState } from "react";
import { Col, Collapse } from "react-bootstrap";
import { SectionHeader } from "./SectionHeader";

export function Experience(props) {
    const [collapse, setCollapse] = useState(true);

    const items = props.data.map((exp, i) => {
        const when = `${exp.startYear} - ${exp.endYear ? exp.endYear : ""}`
        const k = `${exp.name}-${i}`
        const content = Array.isArray(exp.description) ? <ul>{exp.description.map((achvmnt, i1) => { return <li key={`e${i}a${i1}`}>{achvmnt}</li> })}</ul> : exp.description
        return (
            <Col className="pad-15" key={i}>
                <SectionHeader name={exp.name} size={"m"} right={when} sub={exp.role} website={exp.url} />
                <div id="desc">
                    {content}
                </div>
            </Col>
        )
    })
    return (
        <span>
            <div ref={props.reff} style={{ position: 'relative', bottom: 50 }} />
            <SectionHeader name={props.name} collapse={collapse} setCollapse={setCollapse} collapsable="true" />
            <Collapse in={collapse}>
                <div>
                    {items}
                </div>
            </Collapse>
        </span>
    )
}