import React, { useState } from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import { SectionHeader } from "./SectionHeader";

export function Skills(props) {
    const [collapse, setCollapse] = useState(true);

    const skills = props.data.map((skill, i) => {
        return (
            <Col m={12} lg={6} key={`s${i}`}>
                <SectionHeader name={skill.name} size={"s"} />
                {skill.tools.map((tool, i1) => {
                    return (
                        <div className="pad-15" key={`s${i}t${i1}`}>
                            <h6><i className="fa-solid fa-caret-right"></i>&nbsp;<b>{tool.level}</b></h6>
                            <p className="desc">{tool.names.join(' - ')}</p>
                        </div>
                    )
                })}
            </Col>
        )
    })
    var groupSize = 2;
    var rows = skills.reduce(function (r, element, index) {
        index % groupSize === 0 && r.push([]);
        r[r.length - 1].push(element);
        return r;
    }, []).map(function (rowContent, i) {
        return <Row className="pad-15" key={i}>{rowContent}</Row>;
    });


    return (
        <span>
            <div ref={props.reff} style={{ position: 'relative', bottom: 50 }} />


            <SectionHeader name={"Skills"} collapse={collapse} setCollapse={setCollapse} collapsable="true" />
            <Collapse in={collapse}>
                <div>
                    {rows}
                </div>
            </Collapse>
        </span>
    )
}