import React, { useState } from "react";
import { Col, Collapse } from "react-bootstrap";
import { SectionHeader } from "./SectionHeader";

export function Education(props) {
    const [collapse, setCollapse] = useState(true);
    return (
        <span>
            <div ref={props.reff} style={{ position: 'relative', bottom: 50 }} />
            <SectionHeader name={"Education"} collapse={collapse} setCollapse={setCollapse} collapsable="true" />
            <Collapse in={collapse}>
                <div >

                    {props.data.map((level, i) => {
                        const when = `${level.startYear} - ${level.endYear ? level.endYear : ""}`
                        return (
                            <Col className="pad-15" key={i}>
                                <SectionHeader name={level.level} size={"m"} right={when} sub={level.institution} website={level.website} />
                                <div id="desc">
                                    {Array.isArray(level.description) ? <ul>{level.description.map(achvmnt => { return <li>{achvmnt}</li> })}</ul> : level.description}
                                </div>
                            </Col>
                        )
                    })}
                </div>
            </Collapse>
        </span >

    )
}