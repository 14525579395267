import React from "react";

export function BasicInformationItem(props) {
    const items = props.data.map((item, i) => {
        const className = `fa-solid fa-${item.icon}`
        return (
            <tr id="item" key={i}>
                {item.role && item.role == 'cv' && <a rel="noreferrer" target="_blank" className="link1" href="/Parham_Khamsepour_CV.pdf"><td className="icon"><i className={className}></i></td><td><p>{Array.isArray(item.value) ? item.value.join(", ") : item.value}</p></td></a>}
                {!item.role && <><td className="icon"><i className={className}></i></td><td><p>{Array.isArray(item.value) ? item.value.join(", ") : item.value}</p></td></>}
            </tr >
        );

    })
    return (
        [items]
    )
}