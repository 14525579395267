import React from "react";
import { NamePhoto } from "./NamePhoto";
import { BasicInformation } from "./BasicInformation";
import { Socials } from "./Socials";

export function SideBar(props) {
    return (

        <div className="sidebar">
            <div ref={props.pictureRef} style={{ position: 'relative', bottom: 50 }} />
            <NamePhoto data={props.data} />
            <BasicInformation data={props.data} />
            <Socials data={props.data} />
        </div>
    );
}