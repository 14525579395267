import React, { useState } from "react";
import { Container, Form, Nav, Navbar } from "react-bootstrap";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, NavGlobalStyle, NavLinkGlobalStyle, NavToggle, NavToggleIcon } from "./GlobalStyles";
import { darkTheme, lightTheme } from "./Themes";

export function Header(props) {

    const [theme, setTheme] = useState(() => {
        const saved = localStorage.getItem("mode");
        if (saved) {
            return saved;
        } else {
            return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        }
    });
    const themeToggler = () => {
        const mode = theme === 'light' ? 'dark' : 'light'
        localStorage.setItem('mode', mode)
        setTheme(mode)
    }
    const profileClicked = (e) => {
        props.profileRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const skillsClicked = (e) => {
        props.skillsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const educationClicked = (e) => {
        props.educationRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const experienceClicked = (e) => {
        props.experienceRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const picClicked = (e) => {
        props.pictureRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const projectExperienceClicked = (e) => {
        props.projectExperienceRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const publicationsClicked = (e) => {
        props.publicationsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const c = props.fixed === 'true' ? "fixed-top" : "sticky-top"

    return (
        <Navbar className={c} expand="md">
            <Container>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" id="nav-toggle" >
                    <i className="fa-solid fa-bars"></i>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {props.pictureRef && <Nav.Link href="#" onClick={picClicked}>Me</Nav.Link>}

                        <Nav.Link href="#" onClick={profileClicked}>Profile</Nav.Link>
                        {props.data.experience.work && props.data.experience.work.length > 0 && <Nav.Link href="#" onClick={experienceClicked}>Work Experience</Nav.Link>
                        }
                        {props.data.education && props.data.education.length > 0 && <Nav.Link href="#" onClick={educationClicked}>Education</Nav.Link>
                        }
                        {props.data.skills && props.data.skills.length > 0 && <Nav.Link href="#" onClick={skillsClicked}>Skills</Nav.Link>
                        }
                        {props.data.publications && props.data.publications.length > 0 && <Nav.Link href="#" onClick={publicationsClicked}>Publications</Nav.Link>
                        }
                        {props.data.experience.project && props.data.experience.project.length > 0 && <Nav.Link href="#" onClick={projectExperienceClicked}>Project Experience</Nav.Link>
                        }
                    </Nav>
                </Navbar.Collapse>
                <Nav>
                    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
                        <GlobalStyles />
                        <NavGlobalStyle />
                        <NavLinkGlobalStyle />
                        <NavToggle />
                        <NavToggleIcon />
                        <i className={theme === 'light' ? 'fa-solid fa-moon' : 'fa-solid fa-sun'} id="mode-toggle" onClick={themeToggler}></i>
                    </ThemeProvider>
                </Nav>
            </Container>
        </Navbar >
    )
}