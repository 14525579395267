import React, { useState } from "react";
import { Col, Collapse } from "react-bootstrap";
import { SectionHeader } from "./SectionHeader";

export function Publication(props) {
    const [collapse, setCollapse] = useState(true);

    const items = props.data.map((publication, i) => {
        return (
            <Col className="pad-15" key={i}>
                <SectionHeader name={`${publication.title}`} size={"m"} right={publication.publishedAt ? publication.publishedAt : ""} sub={`${publication.publication}`} />
                <p id="desc">
                    {publication.abstract}&nbsp;{publication.url ? <a rel="noreferrer" target="_blank" className="link" href={publication.url}>Avaialbe Here</a> : <b>Not Available Online</b>}
                </p>
            </Col>

        );
    });


    return (
        <span>
            <div ref={props.reff} style={{ position: 'relative', bottom: 50 }} />
            <SectionHeader name={props.name} collapse={collapse} setCollapse={setCollapse} collapsable="true" />
            <Collapse in={collapse}>
                <div>

                    {items}
                </div>
            </Collapse>
        </span>

    )
}