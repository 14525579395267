import React, { useState } from "react";
import { Col, Collapse } from "react-bootstrap";
import { SectionHeader } from "./SectionHeader";

export function Profile(props) {
    const [collapse, setCollapse] = useState(true);

    return (
        <span>
            <div ref={props.reff} style={{ position: 'relative', bottom: 50 }} />

            <SectionHeader name={"Profile"} collapse={collapse} setCollapse={setCollapse} collapsable="true" />
            <Collapse in={collapse}>
                <div>
                    <Col className="pad-15">
                        <p id="desc">{props.data.personal.profile}</p>
                    </Col>
                </div>
            </Collapse>
        </span>
    )
}