import { createGlobalStyle } from "styled-components"
export const GlobalStyles = createGlobalStyle`

    body {
        background: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};
      },
  `
export const NavGlobalStyle = createGlobalStyle`
.navbar{
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text} !important;
}
`
export const NavLinkGlobalStyle = createGlobalStyle`
.nav-link{
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text} !important;
    transition: none;
}

`

export const NavToggle = createGlobalStyle`
#nav-toggle{
    border-color: ${({ theme }) => theme.text} !important;
}
`
export const NavToggleIcon = createGlobalStyle`
.fa-bars{
    color: ${({ theme }) => theme.text} !important;

}
`