import React from "react";

export function SectionHeader(props) {

    let header = <h3>{props.name}</h3>
    let content = <div className="hr" ></div>
    if (props.size) {
        if (props.size === "s") {
            header = <h5>{props.name}</h5>
            content = <div className="hr sub" ></div>
        }
        if (props.size === "m") {
            header = <h4>{props.name}</h4>
            content = <div className="hr sub" ></div>
        }
    }
    if (props.collapsable) {
    }
    return (
        <div className="basic-card">
            <span className={props.collapsable ? "section-span clickable" : "section-span"} onClick={() => { if (props.collapsable) { props.setCollapse(!props.collapse) } }}>
                <span id="subtitle">
                    <span id="sub-web">
                        {header}&nbsp;

                        {props.website && <a target="_blank" rel="noreferrer" className="link" href={props.website}>Website</a>}
                    </span>
                    <h5 className="light">{props.sub && props.sub}</h5>

                </span>
                <b>
                    {props.right && props.right}
                </b>
                {props.collapsable &&
                    <i className={props.collapse ? "fa-solid fa-angle-up" : "fa-solid fa-angle-down"}></i>

                }
            </span>
            {content}
        </div>
    );
}