import './Styles/App.scss';
import { Header } from './Components/Header';
import { SideBar } from './Components/SideBar';
import { Col, Container, Row } from 'react-bootstrap';
import { Home } from './Components/Home';
import { useEffect, useRef, useState } from 'react';
import { DATA } from './Constants';

function App() {
  const [isDesktop, setDesktop] = useState(window.innerWidth >= 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth >= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const profileRef = useRef(null)
  const skillsRef = useRef(null)
  const educationRef = useRef(null)
  const experienceRef = useRef(null)
  const pictureRef = useRef(null)
  const projectExperienceRef = useRef(null)
  const publicationsRef = useRef(null);


  return (
    <div>
      {isDesktop ? (
        <Container>
          <Row>
            <Col s={12} md={4}>
              <SideBar data={DATA} />
            </Col>
            <Col s={12} md={8}>
              <Header data={DATA} profileRef={profileRef} skillsRef={skillsRef} educationRef={educationRef} publicationsRef={publicationsRef} projectExperienceRef={projectExperienceRef} experienceRef={experienceRef} />
              <Home data={DATA} profileRef={profileRef} skillsRef={skillsRef} educationRef={educationRef} publicationsRef={publicationsRef} projectExperienceRef={projectExperienceRef} experienceRef={experienceRef} />

            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col s={12}>
              <Header data={DATA} profileRef={profileRef} skillsRef={skillsRef} educationRef={educationRef} publicationsRef={publicationsRef} projectExperienceRef={projectExperienceRef} experienceRef={experienceRef} pictureRef={pictureRef} fixed="true" />
            </Col>
          </Row>
          <Row>

            <Col s={12} id="mobile-nav">

              <SideBar data={DATA} pictureRef={pictureRef} />
            </Col>
          </Row>
          <Row>

            <Col s={12}>

              <Home data={DATA} profileRef={profileRef} skillsRef={skillsRef} projectExperienceRef={projectExperienceRef} publicationsRef={publicationsRef} educationRef={educationRef} experienceRef={experienceRef} />

            </Col>
          </Row>
        </Container>
      )
      }
    </div >
  );
}

export default App;
