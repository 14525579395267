export const lightTheme = {
    body: '#FFF',
    text: '#000',
    toggleBorder: '#FFF',
    background: '#000',
}
export const darkTheme = {
    body: '#141414ff',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    background: '#999',
}
