import React from "react";
import { BasicInformationItem } from "./BasicInformationItem";
import { SectionHeader } from "./SectionHeader";

export function BasicInformation(props) {
    return (
        <span>
            <SectionHeader name={"Basic Information"} />
            <table>
                <tbody>

                    <BasicInformationItem data={props.data.personal.basicInformation} />
                </tbody>
            </table>
        </span>

    );
}