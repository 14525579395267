import React from "react";

export function NamePhoto(props) {
    return (
        <div className="basic-card">
            <img src={props.data.personal.avatar} alt="me" />
            <div className="names-card">
                <h1 className="first-name">
                    {props.data.personal.name.split(" ")[0]}&nbsp;
                </h1>
                <h1 className="last-name">
                    {props.data.personal.name.split(" ")[1]}
                </h1>
            </div>
        </div>
    )
}