import React from "react";
import { SectionHeader } from "./SectionHeader";
import { SocialItem } from "./SocialItem";

export function Socials(props) {
    return (
        <span>
            <SectionHeader name={"Socials"} />
            <SocialItem data={props.data.personal.socials} />
        </span>

    )
}