import React from "react";
import { Education } from "./Education";
import { Experience } from "./Experience";
import { Profile } from "./Profile";
import { Publication } from "./Publication";
import { Skills } from "./Skills";

export function Home(props) {
    return (
        <span>
            <Profile data={props.data} reff={props.profileRef} />
            {props.data.experience.work && props.data.experience.work.length > 0 && <Experience data={[...props.data.experience.work].reverse()} reff={props.experienceRef} name="Work Experience" />}
            {props.data.education && props.data.education.length > 0 && <Education data={[...props.data.education].reverse()} reff={props.educationRef} />}
            {props.data.publications && props.data.publications.length > 0 && <Publication data={props.data.publications} reff={props.publicationsRef} name="Publications" />}
            {props.data.skills && props.data.skills.length > 0 && <Skills data={props.data.skills} reff={props.skillsRef} />}
            {props.data.experience.project && props.data.experience.project.length > 0 && <Experience data={[...props.data.experience.project].reverse()} reff={props.projectExperienceRef} name="Project Experience" />}

        </span>

    )
}