export const DATA = {
    personal: {
        avatar: "https://i.imgur.com/DciSXiW.jpg",
        name: "Parham Khamsepour",
        profile: "I am Parham Khamsepour, a computer scientist and PhD student at University of Ottawa, specializing in artificial intelligence. I have a strong background in algorithms and data structures, and have experience in solving complex problems through my MSc degree at University of Windsor. I have also been working as a web development mentor, guiding and teaching others in the field. I am particularly motivated by the opportunity to tackle challenging problems and make meaningful contributions to both the field, and others through my skills in problem-solving and software development.",
        basicInformation: [
            {
                icon: 'envelope',
                value: 'khamsep@uwindsor.ca'
            },
            {
                icon: 'envelope',
                value: 'contact@parhamkhamsepour.com'
            },
            {
                icon: 'phone',
                value: '+1 226 506 4245'
            },
            {
                icon: 'language',
                value: ['Persian', 'English']
            },
            {
                icon: 'cake-candles',
                value: 'June 22, 1998'
            },
            {
                icon: 'location-dot',
                value: 'ON, Canada'
            },
            {
                icon: 'file-arrow-down',
                value: 'Resumé',
                role: 'cv'
            }
        ],
        socials: [
            {
                icon: 'linkedin-in',
                url: 'https://www.linkedin.com/in/parhamkhamsepour/'
            },
            {
                icon: 'github',
                url: 'https://github.com/parham-box'
            }
        ]
    },
    skills: [
        {
            name: "Machine Learning and Data Science",
            tools: [
                {
                    level: "Academic & Experienced",
                    names: ["Python", "Langchain", "LLMs", "HuggingFace"]
                }
            ]
        },
        {
            name: "Back-End/RESTFul API Development",
            tools: [
                {
                    level: "Experienced",
                    names: ["Node.JS", "Ruby(Rails)", "PHP(Laravel)", "Express"]
                },
                {
                    level: "Familiar",
                    names: ["Python(Django)", "C#(ASP.NET MVC)"]
                }
            ]
        },
        {
            name: "Mobile Application Development",
            tools: [
                {
                    level: "Experienced",
                    names: ["Swift", "SwiftUI", "Java"]
                },
                {
                    level: "Familiar",
                    names: ["Flutter"]
                }
            ]
        },

        {
            name: "Front-end Development",
            tools: [
                {
                    level: "Experienced",
                    names: ["React", "HTML", "CSS", "JavaScript"]
                },
                {
                    level: "Familiar",
                    names: ["Vue"]
                }
            ]
        },
        {
            name: "Databases",
            tools: [
                {
                    level: "Experienced",
                    names: ["MySQL", "PostgreSQL", "SQLServer"]
                },
                {
                    level: "Familiar",
                    names: ["MongoDB"]
                }
            ]
        },
        {
            name: "Creative Design",
            tools: [
                {
                    level: "Experienced",
                    names: ["Draw.io", "IPE"]
                },
                {
                    level: "Experienced-ish",
                    names: ["Adobe Photoshop"]
                },
                {
                    level: "Have Worked With",
                    names: ["Adobe Illustrator", "Adobe Xd"]
                }
            ]
        },
        {
            name: "DevOps",
            tools: [
                {
                    level: "Experienced",
                    names: ["Kubernetes", "Docker", "OpenShift", "Azure DevOps"]
                }
            ]
        }
        , {
            name: "Others",
            tools: [
                {
                    level: "Experienced",
                    names: ["GraphQL","MVC", "MVVM","Java", "C", "C++", "Regular Expressions", "P5.js", "Processing", "Microsoft Office", "LaTeX"]
                }
            ]
        },
    ],
    education: [
        {
            level: "High School",
            major: "Mathematics",
            institution: "Pasargad High School",
            startYear: "2012",
            endYear: "2016",
            description: "I studied Physics and Mathematics and ranked in the top 4% among 162731 participants of Iran's university entrance exam in 2016. Graduated with GPA: 18.61/20"
        },
        {
            level: "Bachelor Degree",
            major: "Software Engineering",
            institution: "Imam Khomeini International University, Qazvin, Iran",
            startYear: "2016",
            endYear: "2020",
            website: "https://ikiu.ac.ir",
            description: "I studied software engineering for 4 years in this institution and graduated with a total GPA of 17.66/20 which was within the first 10% of students. Moreover, I was selected for computer olympiad team of university and participated in the ICPC international collegiate programming contest as a member of the my universities team."
        },
        {
            level: "Master's Degree (Thesis Based)",
            major: "Computer Science",
            institution: "University Of Windsor, Ontario, Canada",
            startYear: "2021",
            endYear: "2023",
            website: "https://uwindsor.ca",
            description: "During my time studying for my MSc, I had the privilege of studying under the supervision of Dr. Biniaz at the University of Windsor. I specialized in artificial intelligence and focused my research on algorithms and data structures. The highlight of my studies was my thesis, titled 'The Minimum Consistent Spanning Subset Problem on Trees'. My hard work and dedication to my studies were recognized through my selection as a Graduate Assistant multiple times and winning multiple awards and scholarships. My MSc studies were a great opportunity for me to gain knowledge and skills in Artificial intelligence and also to improve my abilities in Algorithms and Data structures field."
        },
        {
            level: "Doctor of Philosophy",
            major: "Computer Science",
            institution: "University Of Ottawa, Ontario, Canada",
            startYear: "2024",
            endYear: "",
            website: "https://uottawa.ca",
            description: "Researchinh and working in the field of AI and LLMs under the supervision of Dr. Nejati."
        }
    ],
    experience: {
        work: [
            {
                name: "Nahira Co.",
                role: "iOS Developer",
                description: "Worked at Nahira Co as an iOS developer working on various iOS projects using Swift",
                startYear: "2018",
                endYear: "2018"
            },
            {
                name: "Reeno Co.",
                role: "Intern Engineer",
                description: "This website was developed for a festival with a 20 days period for Qazvin's branch of Iran's Ministry of Education. Purpose of this website was to collect and recycle from students all across Qazvin. This website was developed with PHP and Laravel framework as my Internship project for bachelor degree.",
                startYear: "2019",
                endYear: "2019"
            },
            {
                name: "Motion Gestures",
                role: "iOS Developer",
                description: [
                    "Demonstrated experience in utilizing Swift to implement efficient and effective image processing tasks.",
                    "Utilized Apple’s local frameworks to extract different layers of image from camera and processing it using the local gesture recognition model."
                  ] ,
                startYear: "Nov 2021",
                endYear: "Jan 2022"
            },
            {
                name: "University of Windsor",
                role: "Research Assistant",
                description: "Researching on Algorithm and Data structures under the supervision of Ahmad Biniaz",
                startYear: "Sep 2021",
                endYear: "April 2023",
                url: "https://uwindsor.ca"
            },
            {
                name: "University of Windsor",
                role: "Graduate Teaching Assistant",
                description: [
                    "Instructed 2 sessions of COMP-2310: Theoretical Foundations of Computer Science to undergraduate students.",
                    "Instructed 1 session of COMP-2650: Computer Architecture to undergraduate students.",
                    "Helped students to write technical reports.",
                    "Solved home assignments for students in class and marked exams and reports."
                  ],
                startYear: "Sep 2021",
                endYear: "April 2023",
                url: "https://uwindsor.ca"
            },
            {
                name: "AgileIS Co",
                role: "Software Developer",
                description: ["Demonstrated expertise in a diverse range of technologies including NestJS, Agile Application Development, Test Automation, GraphQL, Continuous Integration and Continuous Delivery (CI/CD), Microsoft Azure, Dependency Management, and PostgreSQL, encompassing Database Design.","Collaborated with a team of developers in an agile work environment to develop UI and API improvements to CAPT application","Responsible for fixing technical difficulties with the CI/CD pipeline and Expo, and maintaining dependencies up-to-date","Responsible for implementing a new e2e testing system and its integration with in the CI/CD pipeline","Created new UI components and used Storybook for testing them in mobile and web"],
                startYear: "Jun 2023",
                endYear: "Aug 2023",
                url: "https://www.agileis.co"
            },
            {
                name: "LightHouse Labs Co",
                role: "Full-stack Web Development Mentor",
                description: [
                    "Demonstrated expertise in a diverse range of technologies including Node.js, React, Express.js, Ruby on Rails, Algorithms, Data Structures, Databases, and PostgreSQL.",
                    "Assisted students in understanding complex programming concepts and troubleshooting code, leading to improved proficiency in web development.",
                    "Provided mentorship and hands-on experience to students, facilitating the development of their technical skills and helping them achieve their professional goals.",
                    "Successfully served as a Web Development Mentor at Lighthouse Labs, providing guidance and instruction to over 1000 students in the areas of web development and programming."
                  ],                  
                startYear: "Jan 2022",
                endYear: "",
                url: "https://www.lighthouselabs.ca"
            },
        ],
        project: [
            {
                name: "FitSho",
                role: "Front-End, Back-End Developer and DataBase Designer",
                description: "FitSho was a website that provided users with workout and dietary plans. This website was developed completely by me using Laravel and Bootstrap. Also The database design of this project was so complicated that was used as my Bachelors degree' final project.",
                startYear: "2018",
                endYear: "2018"
            },
            {
                name: "Local Search Engine",
                role: "Developer",
                description: "This project was developed as a final project for my Information Retrieval course. We managed to create a simple search engine with ranking that would search on a local dataset. JAVA was used for this project",
                startYear: "2019",
                endYear: "2019"
            },
            {
                name: "Movie Sorter",
                role: "Developer",
                description: "This application was developed using Java to find and sort all the movies inside a directory. The Regular expressions used inside this project are more important than the programming language used for development.",
                startYear: "2019",
                endYear: "2019"
            },
            {
                name: "This Website",
                role: "Developer",
                description: "This website is developed using React. It can be shared and used open sourced as all of the inputs come from a single JSON file.",
                startYear: "2022",
                endYear: "2022"
            },

        ],

    },
    publications: [
        {
            title: "The Minimum Consistent Spanning Subset Problem on Trees",
            authors: ["Parham Khamsepour", "Ahmad Biniaz"],
            type: "Jurnal",
            publishedAt: "2024",
            publication: "Journal of Graph Algorithms and Applications",
            url: "http://dx.doi.org/10.7155/jgaa.v28i1.2929",
            abstract: "Given a vertex-colored edge-weighted graph, the minimum consistent subset (MCS) problem asks for a minimum subset S of vertices such that every vertex v ∉ S has the same color as its nearest neighbor in S. This problem is NP-complete. A recent result of Dey, Maheshwari, and Nandy (2021) gives a polynomial-time algorithm for the MCS problem on two-colored trees. A block is a maximal connected set of vertices of the same color. We introduce a variant of the MCS problem, namely the minimum consistent spanning subset problem, for which we require the set S to contain a vertex from every block of the graph such that every vertex v ∈/ S has a nearest neighbor in S that is in the same block as v. We observe that this problem is NP-hard on general graphs. We present a polynomial-time algorithm for this problem on trees.",
        },
    ]
};
