import React from "react";

export function SocialItem(props) {
    const items = props.data.map((item, i) => {
        const classNameFA = `fa-brands fa-${item.icon}`
        const className = `social-icon ${item.icon}`

        return (
            < a href={item.url} rel="noreferrer" target="_blank" className="social-margin" id="social" key={i}>
                <div className={className}>
                    <i className={classNameFA} aria-hidden="true"></i>
                </div>
            </a>
        );

    })

    return (
        [items]

    )
}